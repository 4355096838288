<template>
  <div id="app" :style="{ background: loginState ? '#f5f5f5' : 'linear-gradient(to right, #3498db, #2c3e50)', minHeight: '100vh' }">
    <div class="content">
      <div v-if="!loginState" class="login-form">
        <el-form :model="loginForm" :inline="true">
          <el-form-item label="E-Posta">
            <el-input v-model="loginForm.username" placeholder="Smart Life Mail"></el-input>
          </el-form-item>
          <el-form-item label="Şifre">
            <el-input type="password" v-model="loginForm.password" placeholder="Smart Life Şifreniz"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login">Giriş Yap</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-else>
        <div class="header">
          <el-button type="default" @click="refreshDevices">Yenile</el-button>
          <el-button type="default" @click="logout">Çıkış Yap</el-button>
        </div>

        <div id="devices">
          <div v-for="device in devicesSorted" :key="device.id">
            <el-card class="device" :style="{ filter: device.data.online === false ? 'opacity(0.65) grayscale(1)' : '' }">
              <el-tooltip effect="light" :content="device.type" :offset="-20" :visible-arrow="false">
                <el-avatar :src="`/device_icons/${device.type}.png`" shape="square">
                  <img src="/device_icons/default.png"/>
                </el-avatar>
              </el-tooltip>
              <span class="device-name">{{ device.name }}</span>
              <template v-if="device.type === 'scene'">
                <el-button type="default" circle size="large" class="trigger" @click="triggerScene(device)">
                  <i class="material-icons-round">play_arrow</i>
                </el-button>
              </template>
              <template v-else>
                <el-button type="default" circle size="large"
                  :class="device.data.state ? 'state-on' : 'state-off'"
                  :disabled="!device.data.online"
                  @click="toggleDevice(device)"
                >
                  <i class="material-icons-round">{{ device.data.online ? 'power_settings_new' : 'cloud_off' }}</i>
                </el-button>
              </template>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import tuya from '@/libs/tuya';

const homeAssistantClient = new tuya.HomeAssistantClient(
  JSON.parse(localStorage.getItem('session'))
);

const loginState = ref(false);
const devices = ref([]);
const loginForm = ref({ username: '', password: '' });

const devicesSorted = computed(() => {
  const order = { true: 0, undefined: 1, false: 2 };
  return devices.value.slice().sort((d1, d2) =>
    order[d1.data.online] > order[d2.data.online] ? 1 : -1
  );
});

onMounted(async () => {
  loginState.value = !!homeAssistantClient.getSession();
  if (!loginState.value) {
    localStorage.clear();
  }
  devices.value = JSON.parse(localStorage.getItem('devices')) || [];
});

const login = async () => {
  try {
    await homeAssistantClient.login(loginForm.value.username, loginForm.value.password);
    localStorage.setItem('session', JSON.stringify(homeAssistantClient.getSession()));
    loginState.value = true;
    loginForm.value = { username: '', password: '' };
    refreshDevices();
  } catch (err) {
    ElMessage.error(`Oops, giriş hatası. (${err})`);
  }
};

const logout = () => {
  homeAssistantClient.dropSession();
  localStorage.clear();
  loginState.value = false;
  loginForm.value = { username: '', password: '' };
  devices.value = [];
};

const refreshDevices = async () => {
  try {
    const discoveryResponse = await homeAssistantClient.deviceDiscovery();
    const discoveryDevices = discoveryResponse.payload.devices || [];
    devices.value = discoveryDevices;
    localStorage.setItem('devices', JSON.stringify(discoveryDevices));
  } catch (err) {
    ElMessage.error(`Oops, cihaz keşfi hatası. (${err})`);
  }
};

const toggleDevice = async (device) => {
  try {
    const newState = !device.data.state;
    await homeAssistantClient.deviceControl(device.id, 'turnOnOff', newState);
    device.data.state = newState;
  } catch (err) {
    ElMessage.error(`Oops, cihaz kontrol hatası. (${err})`);
  }
};

const triggerScene = async (device) => {
  try {
    await homeAssistantClient.deviceControl(device.id, 'turnOnOff', true);
  } catch (err) {
    ElMessage.error(`Oops, cihaz kontrol hatası. (${err})`);
  }
};
</script>

<style scoped>
#app {
  font-family: 'Avenir', 'Helvetica', 'Arial', sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  transition: background 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.login-form .el-form-item {
  margin-bottom: 15px;
}

.login-form .el-button {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.el-card.device {
  max-width: 100%;
  margin-bottom: 16px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: filter 0.3s ease;
}

.el-card.device :deep(.el-card__body) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to right, #3498db, #2c3e50);
  border-radius: 8px;
}

.el-card.device :deep(.el-card__body :last-child) {
  margin-left: auto;
}

.el-button.state-on:enabled {
  color: #f9f9f9;
  background-color: #7dd8ba;
}

.el-button.state-off:enabled {
  color: #a3a4a7;
  background-color: #f9f9f9;
}

.el-button.trigger:enabled {
  color: #f9f9f9;
  background-color: #9eabce;
}

.el-button.el-button--large {
  padding: 9px;
  font-size: 20px;
  line-height: 0px;
}

.el-avatar {
  background: transparent;
  margin-right: 16px;
}
</style>
